var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-12 col-md-12 col-lg-12 p-0"},[_vm._m(0),(_vm.fastCadastroConcluido.status)?_c('div',{staticClass:"d-flex flex-column justify-content-center align-items-center text-center body-page"},[_c('h2',{staticClass:"text-success my-3"},[_vm._v(" "+_vm._s(_vm.fastCadastroConcluido.message)+" ")]),_c('h5',[_vm._v("Verifique seu email para os próximos passos.")]),_vm._m(1)]):_c('div',{staticClass:"bg-light"},[_c('div',{staticClass:"bg-white container justify-content-center"},[_c('div',{staticClass:"d-flex steps-header justify-content-between py-5 align-items-center"},[_vm._m(2),_c('b-icon-arrow-right',{staticClass:"steps-icon"}),_c('span',{class:_vm.steps === 2
              ? 'steps-guide-active text-center'
              : 'steps-guide text-center'},[_vm._v(" Passo 2:"),_c('br'),_vm._v(" Cadastro ")]),_c('b-icon-arrow-right',{staticClass:"steps-icon"}),_c('span',{class:_vm.steps === 3
              ? 'steps-guide-active text-center'
              : 'steps-guide text-center'},[_vm._v(" Passo 3: "),_c('br'),_vm._v(" Documentos ")])],1),(_vm.steps === 2)?_c('div',[_c('div',{staticClass:"text-center justify-content-center d-flex flex-column"},[_c('h3',[_vm._v(_vm._s(this.fastCadastro.titulo_tela_cadastro))]),_c('h6',{staticClass:"bg-secondary rounded text-white mx-auto p-1"},[_vm._v(" Prenchimento obrigatório dados do aluno. ")])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 p-4"},[_c('div',{staticClass:"row"},[_vm._m(3),_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-12 mb-2 d-flex flex-column"},[_c('span',[_vm._v(_vm._s(_vm.fastCadastro.dados_turma ? _vm.fastCadastro.dados_turma.sigla : null)+" - "+_vm._s(_vm.fastCadastro.dados_turma ? _vm.fastCadastro.dados_turma.curso.nome_curso : null))]),_c('span',[_vm._v("Carga horária: "+_vm._s(_vm.fastCadastro.dados_turma ? _vm.fastCadastro.dados_turma.curso.carga_horaria : null))])])]),_c('div',{staticClass:"row my-2"},[_vm._m(4),_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-4"},[_c('label',[_vm._v("Primeiro nome "),(_vm.fastDadosObrigatorios.nome_razao)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.fastCadastro.nome_razao),expression:"fastCadastro.nome_razao"}],class:_vm.fastDadosObrigatorios.nome_razao &&
                      !_vm.fastCadastro.nome_razao
                        ? 'form-control text-danger border border-danger'
                        : 'form-control',attrs:{"type":"text","autocomplete":"no"},domProps:{"value":(_vm.fastCadastro.nome_razao)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.fastCadastro, "nome_razao", $event.target.value)}}})])]),_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-4"},[_c('label',[_vm._v("Sobrenome "),(_vm.fastDadosObrigatorios.sobrenome_fantasia)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.fastCadastro.sobrenome_fantasia),expression:"fastCadastro.sobrenome_fantasia"}],class:_vm.fastDadosObrigatorios.sobrenome_fantasia &&
                      !_vm.fastCadastro.sobrenome_fantasia
                        ? 'form-control text-danger border border-danger'
                        : 'form-control',attrs:{"type":"text","autocomplete":"no"},domProps:{"value":(_vm.fastCadastro.sobrenome_fantasia)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.fastCadastro, "sobrenome_fantasia", $event.target.value)}}})])]),_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-4"},[_vm._m(5),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.fastCadastro.email),expression:"fastCadastro.email"}],class:_vm.fastCadastro.email
                        ? 'form-control'
                        : 'form-control text-danger border border-danger',attrs:{"type":"text","readonly":""},domProps:{"value":(_vm.fastCadastro.email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.fastCadastro, "email", $event.target.value)}}})])]),_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-4"},[_c('label',[_vm._v("Data de nascimento "),(_vm.fastDadosObrigatorios.datanascimento_abertura)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.fastCadastro.datanascimento_abertura),expression:"fastCadastro.datanascimento_abertura"}],class:_vm.fastDadosObrigatorios.datanascimento_abertura &&
                      !_vm.fastCadastro.datanascimento_abertura
                        ? 'form-control text-danger border border-danger'
                        : 'form-control',attrs:{"type":"date","autocomplete":"no"},domProps:{"value":(_vm.fastCadastro.datanascimento_abertura)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.fastCadastro, "datanascimento_abertura", $event.target.value)}}}),_c('small',{staticClass:"text-secondary"},[_vm._v("Necessário ter 19 anos ou mais.")])])]),_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-4"},[_c('label',[_vm._v("CPF "),(_vm.fastDadosObrigatorios.cpf_cnpj)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.fastCadastro.cpf_cnpj),expression:"fastCadastro.cpf_cnpj"},{name:"mask",rawName:"v-mask",value:(['###.###.###-##']),expression:"['###.###.###-##']"}],class:_vm.fastDadosObrigatorios.cpf_cnpj && !_vm.fastCadastro.cpf_cnpj
                        ? 'form-control text-danger border border-danger'
                        : 'form-control',attrs:{"type":"text","autocomplete":"no"},domProps:{"value":(_vm.fastCadastro.cpf_cnpj)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.fastCadastro, "cpf_cnpj", $event.target.value)}}})])]),_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-4"},[_c('label',[_vm._v("Sexo ")]),(_vm.fastDadosObrigatorios.sexo)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e(),_c('div',{staticClass:"form-group"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.fastCadastro.sexo),expression:"fastCadastro.sexo"}],class:_vm.fastDadosObrigatorios.sexo && !_vm.fastCadastro.sexo
                        ? 'form-control text-danger border border-danger'
                        : 'form-control',on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.fastCadastro, "sexo", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Selecione")]),_c('option',{attrs:{"value":"M"}},[_vm._v("Masculino")]),_c('option',{attrs:{"value":"F"}},[_vm._v("Feminino")]),_c('option',{attrs:{"value":"O"}},[_vm._v("Outras opções")])])])]),_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-4"},[_c('label',[_vm._v("Nacionalidade "),(_vm.fastDadosObrigatorios.id_pais_pessoa)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]),_c('img',{staticClass:"mx-2 pb-1",attrs:{"src":_vm.buscaInfoPais(_vm.fastCadastro.id_pais_pessoa, 'bandeira')}}),_c('div',{staticClass:"form-group"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.fastCadastro.id_pais_pessoa),expression:"fastCadastro.id_pais_pessoa"}],class:_vm.fastDadosObrigatorios.id_pais_pessoa &&
                      !_vm.fastCadastro.id_pais_pessoa
                        ? 'form-control text-danger border border-danger'
                        : 'form-control',on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.fastCadastro, "id_pais_pessoa", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.organizaNacionalidade),function(pais){return _c('option',{key:pais.id_pais,domProps:{"value":pais.id_pais}},[_vm._v(" "+_vm._s(pais.nacionalidade)+" ")])}),0)])]),_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-4"},[_c('label',[_vm._v("RG")]),(_vm.fastDadosObrigatorios.rg)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e(),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.fastCadastro.rg),expression:"fastCadastro.rg"}],class:_vm.fastDadosObrigatorios.rg && !_vm.fastCadastro.rg
                        ? 'form-control text-danger border border-danger'
                        : 'form-control',attrs:{"type":"text","autocomplete":"no"},domProps:{"value":(_vm.fastCadastro.rg)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.fastCadastro, "rg", $event.target.value)}}})])]),_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-4"},[_c('label',[_vm._v("Órgão Emissor do RG")]),(_vm.fastDadosObrigatorios.orgao_emissor)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e(),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.fastCadastro.orgao_emissor),expression:"fastCadastro.orgao_emissor"}],class:_vm.fastDadosObrigatorios.orgao_emissor &&
                      !_vm.fastCadastro.orgao_emissor
                        ? 'form-control text-danger border border-danger'
                        : 'form-control',attrs:{"type":"text","autocomplete":"no"},domProps:{"value":(_vm.fastCadastro.orgao_emissor)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.fastCadastro, "orgao_emissor", $event.target.value)}}})])]),_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-4"},[_c('label',[_vm._v("Naturalidade")]),(_vm.fastDadosObrigatorios.naturalidade)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e(),_c('small',[_vm._v("(cidade de nascimento)")]),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.fastCadastro.naturalidade),expression:"fastCadastro.naturalidade"}],class:_vm.fastDadosObrigatorios.naturalidade &&
                      !_vm.fastCadastro.naturalidade
                        ? 'form-control text-danger border border-danger'
                        : 'form-control',attrs:{"type":"text","autocomplete":"no"},domProps:{"value":(_vm.fastCadastro.naturalidade)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.fastCadastro, "naturalidade", $event.target.value)}}})])])]),_c('div',{staticClass:"row"},[_vm._m(6),_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-4"},[_c('label',[_vm._v("DDI "),(_vm.fastDadosObrigatorios.id_pais_telefone)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]),_c('img',{staticClass:"mx-2 pb-1",attrs:{"src":_vm.buscaInfoPais(_vm.fastCadastro.id_pais_telefone, 'bandeira')}}),_c('div',{staticClass:"form-group"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.fastCadastro.id_pais_telefone),expression:"fastCadastro.id_pais_telefone"}],class:_vm.fastDadosObrigatorios.id_pais_telefone &&
                      !_vm.fastCadastro.id_pais_telefone
                        ? 'form-control text-danger border border-danger'
                        : 'form-control',on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.fastCadastro, "id_pais_telefone", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.organizaDDI),function(pais){return _c('option',{key:pais.id_pais,domProps:{"value":pais.id_pais}},[_vm._v(" "+_vm._s(pais.iso3)+" +"+_vm._s(pais.fone)+" ")])}),0)])]),_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-4"},[_c('label',[_vm._v("Telefone "),(_vm.fastDadosObrigatorios.telefone)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.fastCadastro.telefone),expression:"fastCadastro.telefone"},{name:"mask",rawName:"v-mask",value:(['(##) ####-####', '(##) #####-####']),expression:"['(##) ####-####', '(##) #####-####']"}],class:_vm.fastDadosObrigatorios.telefone && !_vm.fastCadastro.telefone
                        ? 'form-control text-danger border border-danger'
                        : 'form-control',attrs:{"type":"text","autocomplete":"no"},domProps:{"value":(_vm.fastCadastro.telefone)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.fastCadastro, "telefone", $event.target.value)}}})])])]),_c('div',{staticClass:"row"},[_vm._m(7),_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-4"},[_c('label',[_vm._v("País "),(_vm.fastDadosObrigatorios.id_pais_endereco)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]),_c('img',{staticClass:"mx-2 pb-1",attrs:{"src":_vm.buscaInfoPais(_vm.fastCadastro.id_pais_endereco)}}),_c('div',{staticClass:"form-group"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.fastCadastro.id_pais_endereco),expression:"fastCadastro.id_pais_endereco"}],class:_vm.fastDadosObrigatorios.id_pais_endereco &&
                      !_vm.fastCadastro.id_pais_endereco
                        ? 'form-control text-danger border border-danger'
                        : 'form-control',on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.fastCadastro, "id_pais_endereco", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.organizaPaises),function(pais){return _c('option',{key:pais.id_pais,domProps:{"value":pais.id_pais}},[_vm._v(" "+_vm._s(pais.nome)+" ")])}),0)])]),_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-4"},[_c('label',[_vm._v("CEP "),(_vm.fastDadosObrigatorios.cep)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.fastCadastro.cep),expression:"fastCadastro.cep"},{name:"mask",rawName:"v-mask",value:('#####-###'),expression:"'#####-###'"}],class:_vm.fastDadosObrigatorios.cep && !_vm.fastCadastro.cep
                        ? 'form-control text-danger border border-danger'
                        : 'form-control',attrs:{"type":"text","autocomplete":"no"},domProps:{"value":(_vm.fastCadastro.cep)},on:{"keyup":function($event){return _vm.buscaCEPAluno()},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.fastCadastro, "cep", $event.target.value)}}})])]),_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-4"},[_c('label',[_vm._v("Endereço "),(_vm.fastDadosObrigatorios.logradouro)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.fastCadastro.logradouro),expression:"fastCadastro.logradouro"}],class:_vm.fastDadosObrigatorios.logradouro &&
                      !_vm.fastCadastro.logradouro
                        ? 'form-control text-danger border border-danger'
                        : 'form-control',attrs:{"type":"text","maxlength":"100","autocomplete":"no"},domProps:{"value":(_vm.fastCadastro.logradouro)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.fastCadastro, "logradouro", $event.target.value)}}})])]),_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-4"},[_c('label',[_vm._v("Número "),(_vm.fastDadosObrigatorios.numero)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.fastCadastro.numero),expression:"fastCadastro.numero"}],class:_vm.fastDadosObrigatorios.numero && !_vm.fastCadastro.numero
                        ? 'form-control text-danger border border-danger'
                        : 'form-control',attrs:{"type":"text","maxlength":"10","autocomplete":"no"},domProps:{"value":(_vm.fastCadastro.numero)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.fastCadastro, "numero", $event.target.value)}}})])]),_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-4"},[_c('label',[_vm._v("Complemento "),(_vm.fastDadosObrigatorios.complemento)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.fastCadastro.complemento),expression:"fastCadastro.complemento"}],class:_vm.fastDadosObrigatorios.complemento &&
                      !_vm.fastCadastro.complemento
                        ? 'form-control text-danger border border-danger'
                        : 'form-control',attrs:{"type":"text","autocomplete":"no"},domProps:{"value":(_vm.fastCadastro.complemento)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.fastCadastro, "complemento", $event.target.value)}}})])]),_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-4"},[_c('label',[_vm._v("Bairro "),(_vm.fastDadosObrigatorios.bairro)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.fastCadastro.bairro),expression:"fastCadastro.bairro"}],class:_vm.fastDadosObrigatorios.bairro && !_vm.fastCadastro.bairro
                        ? 'form-control text-danger border border-danger'
                        : 'form-control',attrs:{"type":"text","autocomplete":"no"},domProps:{"value":(_vm.fastCadastro.bairro)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.fastCadastro, "bairro", $event.target.value)}}})])]),_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-4"},[_c('label',[_vm._v("Cidade "),(_vm.fastDadosObrigatorios.cidade)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.fastCadastro.cidade),expression:"fastCadastro.cidade"}],class:_vm.fastDadosObrigatorios.cidade && !_vm.fastCadastro.cidade
                        ? 'form-control text-danger border border-danger'
                        : 'form-control',attrs:{"type":"text","autocomplete":"no"},domProps:{"value":(_vm.fastCadastro.cidade)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.fastCadastro, "cidade", $event.target.value)}}})])]),_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-4"},[_c('label',[_vm._v("Estado "),(_vm.fastDadosObrigatorios.uf)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.fastCadastro.uf),expression:"fastCadastro.uf"}],class:_vm.fastDadosObrigatorios.uf && !_vm.fastCadastro.uf
                        ? 'form-control text-danger border border-danger'
                        : 'form-control',attrs:{"type":"text","maxlength":"2","autocomplete":"no"},domProps:{"value":(_vm.fastCadastro.uf)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.fastCadastro, "uf", $event.target.value)}}})])])])]),_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-12 d-flex justify-content-around my-3"},[_c('button',{staticClass:"btn",on:{"click":function($event){return _vm.validaPasso2()}}},[_vm._v("Avançar")])])])]):(_vm.steps === 3)?_c('div',[_c('div',{staticClass:"text-center justify-content-center d-flex flex-column"},[_c('h3',[_vm._v(_vm._s(this.fastCadastro.titulo_tela_cadastro))]),_c('h6',{staticClass:"bg-secondary rounded text-white mx-auto p-1"},[_vm._v(" Preenchimento obrigatório documentos do aluno. ")]),_c('div',[(_vm.enviodocs.habilitaEnvio)?_c('div',[_c('uploader',{staticClass:"uploader-example",attrs:{"file-status-text":_vm.statusText,"options":_vm.optionsArquivo},on:{"file-success":_vm.fileSuccess,"file-added":_vm.fileValidation}},[_c('uploader-unsupport'),_c('uploader-drop',[_c('p',{staticClass:"aluno_font_color"},[_vm._v(" Arraste um "+_vm._s(_vm.fastDocumentoParaEnvio.nome_documento)+" aqui ou ")]),_c('uploader-btn',{attrs:{"attrs":_vm.restrictArquivo}},[_vm._v(" Clique aqui para selecionar ")])],1),_c('uploader-list')],1),_c('button',{staticClass:"btn my-3",on:{"click":function($event){_vm.enviodocs.habilitaEnvio = false}}},[_vm._v(" Cancelar ")])],1):_c('table',{staticClass:"table table-sm"},[_vm._m(8),(
                  _vm.fastDadosObrigatorios
                    .fast_secretaria_matricula_requisito_documento.length
                )?_c('tbody',_vm._l((_vm.fastDadosObrigatorios.fast_secretaria_matricula_requisito_documento),function(doc){return _c('tr',{key:doc.id_matricula_requisito_documento},[_c('td',[_vm._v(" "+_vm._s(doc.nome_documento)+" "),(doc.obrigatorio)?_c('small',{staticClass:"text-danger"},[_vm._v("(obrigatório)")]):_c('small',{staticClass:"text-secondary"},[_vm._v("(opcional)")])]),(doc.docEnviado)?_c('td',[_c('span',{staticClass:"text-success"},[_vm._v("Documento enviado!")]),_c('br')]):_c('td',[_c('span',{staticClass:"text-danger"},[_vm._v("Não enviado")]),_vm._v(" "),_c('br'),_c('button',{staticClass:"btn btn-send-file",on:{"click":function($event){return _vm.mostraEnvio(doc)}}},[_vm._v(" Clique para enviar ")])])])}),0):_c('tbody',[_vm._m(9)])])])]),(!_vm.enviodocs.habilitaEnvio)?_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-12 d-flex justify-content-around my-3"},[_c('button',{staticClass:"btn",on:{"click":function($event){_vm.steps = 2}}},[_vm._v("Voltar")]),_c('button',{staticClass:"btn btn-finaliza",on:{"click":function($event){return _vm.validaPasso3eFinaliza()}}},[_vm._v(" Finalizar ")])]):_vm._e()]):_vm._e()])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"fasteadHeader d-flex justify-content-around"},[_c('img',{staticClass:"my-2",attrs:{"src":require("../../assets/images/nova-logo-fastead-completo.png"),"alt":"logo-fastead"}}),_c('img',{staticClass:"my-2",attrs:{"src":require("../../assets/images/concurseiro-logo-2.png"),"alt":"logo-concurseiro"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('h6',{staticClass:"my-2"},[_vm._v(" Caso necessário, "),_c('a',{staticClass:"btn btn-primary mx-1",attrs:{"href":"https://fastead.sprinthub.site/r/ja-sou-aluno","target":"_blank"}},[_vm._v("clique aqui")]),_vm._v(" para entrar em contato com a secretaria. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"steps-guide steps-guide-disabled text-center"},[_vm._v(" Passo 1:"),_c('br'),_vm._v(" Matrícula ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-12 col-md-12 col-lg-12 mb-2"},[_c('h5',[_vm._v("Dados da matrícula")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-12 col-md-12 col-lg-12 mb-2"},[_c('h5',[_vm._v("Dados primários")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',[_vm._v("Email "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-12 col-md-12 col-lg-12 mb-2"},[_c('h5',[_vm._v("Telefone")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-12 col-md-12 col-lg-12 mb-2"},[_c('h5',[_vm._v("Endereço")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"thead-dark"},[_c('tr',[_c('th',[_vm._v("Documento")]),_c('th',[_vm._v("Status")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_c('h6',{staticClass:"m-4 text-center"},[_vm._v(" Não foram cadastrados documentos para envio. ")])])])
}]

export { render, staticRenderFns }